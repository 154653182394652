var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.header.shows ? _c('v-app-bar', {
    attrs: {
      "fixed": "",
      "height": "56"
    }
  }, [_vm.isLeftNav(_vm.LEFT_NAV_MODES.MENU) ? _c('v-btn', {
    staticClass: "v-btn--none-active-bg",
    attrs: {
      "icon": "",
      "tile": ""
    },
    on: {
      "click": function click($event) {
        return _vm.setShowsDrawer(true);
      }
    }
  }, [_c('v-img', {
    attrs: {
      "max-width": "34",
      "src": "/images/symbol.svg"
    }
  })], 1) : _vm._e(), _vm.isLeftNav(_vm.LEFT_NAV_MODES.HOME) ? _c('v-btn', {
    staticClass: "v-btn--none-active-bg",
    attrs: {
      "icon": "",
      "tile": ""
    },
    on: {
      "click": _vm.goHome
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "26",
      "color": "grey darken-4"
    }
  }, [_vm._v("mdi-chevron-left")])], 1) : _vm._e(), _vm.isLeftNav(_vm.LEFT_NAV_MODES.BACK) ? _c('v-btn', {
    staticClass: "v-btn--none-active-bg",
    attrs: {
      "icon": "",
      "tile": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "26",
      "color": "grey darken-4"
    }
  }, [_vm._v("mdi-chevron-left")])], 1) : _vm._e(), _c('v-spacer'), _vm.title == 'loading' ? _c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "size": "24",
      "width": "2"
    }
  }) : _vm._e(), _c('h2', {
    staticClass: "v-app-bar__tit d-flex flex-column mt-6"
  }, [_c('span', [_vm._v(_vm._s(_vm.title))]), _c('span', {
    staticClass: "subtitle-2"
  }, [_vm._v(_vm._s(_vm.userText))])]), _c('v-spacer'), _c('v-btn', {
    staticClass: "v-btn--none-active-bg",
    attrs: {
      "icon": "",
      "tile": ""
    },
    on: {
      "click": _vm.logout
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('v-icon', {
    attrs: {
      "size": "26",
      "color": "grey"
    }
  }, [_vm._v("$lock_open_outline")]), _c('div', {
    staticClass: "font-size-10 grey--text text--darken-4 mt-2"
  }, [_vm._v("로그아웃")])], 1)])], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }