var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.showsNavigation ? _c('v-bottom-navigation', {
    staticClass: "justify-space-around",
    attrs: {
      "fixed": "",
      "height": "56"
    }
  }, [_c('v-btn', _vm._b({
    attrs: {
      "exact-path": "",
      "to": "/home",
      "color": "grey"
    }
  }, 'v-btn', _vm.attrs_btn, false), [_c('v-icon', {
    attrs: {
      "size": "24"
    }
  }, [_vm._v("$home_round")]), _c('span', {
    staticClass: "font-size-12 ml-4"
  }, [_vm._v("홈")])], 1), _c('v-btn', _vm._b({
    attrs: {
      "to": "/mypage/purchase-orders",
      "color": "grey"
    }
  }, 'v-btn', _vm.attrs_btn, false), [_c('v-icon', {
    attrs: {
      "size": "24"
    }
  }, [_vm._v("$list_alt_outline")]), _c('span', {
    staticClass: "font-size-12 ml-4"
  }, [_vm._v("발주이력")])], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }