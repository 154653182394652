<template>
    <v-bottom-navigation v-if="showsNavigation" fixed height="56" class="justify-space-around">
        <v-btn v-bind="attrs_btn" exact-path to="/home" color="grey">
            <v-icon size="24">$home_round</v-icon>
            <span class="font-size-12 ml-4">홈</span>
        </v-btn>
        <v-btn v-bind="attrs_btn" to="/mypage/purchase-orders" color="grey">
            <v-icon size="24">$list_alt_outline</v-icon>
            <span class="font-size-12 ml-4">발주이력</span>
        </v-btn>
    </v-bottom-navigation>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
const attrs_btn = {
    icon: true,
    tile: true,
};
export default {
    data: () => ({
        attrs_btn,
    }),
    watch: {
        hasCoinBonus() {
            if (this.hasCoinBonus) this.getRemainCoins();
        },
    },
    computed: {
        ...mapState(["remainCoins"]),
        ...mapState("ui", ["showsNavigation"]),
        ...mapGetters(["hasCoinBonus"]),
        hasRemainCoins() {
            return this.remainCoins.screenTime > 0;
        },
    },
    methods: {
        ...mapActions(["getRemainCoins"]),
    },
};
</script>

<style lang="scss" scoped>
.app-wrapper--desktop .v-bottom-navigation.v-bottom-navigation--fixed {
    width: calc(50vh - 2px);
    bottom: 0;
    left: 50%;
    -webkit-transform: translate(-50%, 0) !important;
    -ms-transform: translate(-50%, 0) !important;
    transform: translate(-50%, 0) !important;
}
.app-wrapper--mobile .v-bottom-navigation.v-bottom-navigation--fixed {
    width: 100%;
}

// v-bottom-navigation
.v-item-group.v-bottom-navigation {
    box-shadow: none;
    border-top: 1px solid var(--border-color);
}
.v-item-group.v-bottom-navigation .v-btn {
    min-width: auto;
    max-width: none;
    width: 100%;
    height: 100% !important;
}
.v-item-group.v-bottom-navigation .v-btn--active {
    color: var(--v-primary-base) !important;
    background-color: var(--v-primary-lighten5) !important;
}
::v-deep.v-item-group.v-bottom-navigation .v-btn .v-btn__content {
    flex-direction: row;
}
::v-deep.v-item-group.v-bottom-navigation .v-btn .v-btn__content > :not(.v-icon) {
    line-height: 1;
}
</style>
