<template>
    <div id="app-wrapper" :class="isMobile ? 'app-wrapper--mobile' : 'app-wrapper--desktop'">
        <v-app id="app" :style="style">
            <app-header />
            <router-view :key="$i18n.locale" />
            <app-footer />
        </v-app>
    </div>
</template>

<script>
import AppHeader from "./components/client/inc/app-header.vue";
import AppFooter from "./components/client/inc/app-footer.vue";

import detect from "./plugins/detect";
import { mapActions } from "vuex";

export default {
    components: {
        AppHeader,
        AppFooter,
    },
    data() {
        return {
            timer: null,
            latest: Date.now(),
            isMobile: detect == "mobile",
        };
    },
    computed: {
        refreshToken() {
            return this.$store.state.refreshToken;
        },
        accessToken() {
            return this.$store.state.accessToken;
        },
        payload() {
            return JSON.parse(atob(this.refreshToken?.split(".")[1] || "") || "{}");
        },
        user() {
            return this.$store.state.user;
        },
        style() {
            return this.isMobile ? "width: 100vw" : "width: 50vh";
        },
    },
    created() {
        this.init();
        this.startScreenTime();
    },
    beforeDestroy() {
        this.stopScreenTime();
    },
    watch: {
        accessToken() {
            if (!this.accessToken) {
                alert("로그아웃 되었습니다");
                this.$router.push("/");
            }
        },
    },
    methods: {
        ...mapActions(["startScreenTime", "stopScreenTime"]),
        init() {
            document.addEventListener("mousemove", this.reset, false);
            document.addEventListener("mousedown", this.reset, false);
            document.addEventListener("keypress", this.reset, false);
            document.addEventListener("touchmove", this.reset, false);
            document.addEventListener("onscroll", this.reset, false);

            this.onTimer();
        },
        reset() {
            this.latest = Date.now();
        },
        onTimer() {
            clearTimeout();
            this.timer = setTimeout(() => {
                if (24 * 60 * 60 * 1000 < Date.now() - this.latest || this.payload.exp * 1000 <= Date.now()) {
                    this.$store.dispatch("logout");
                }
                this.onTimer();
            }, 1000);
        },
    },
};
</script>
<style lang="scss" scoped>
body {
    background-color: #eee;
}
#app-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
#app {
    width: 100vw;
    flex-grow: 0;
    overflow: hidden;
    background-color: white;
}
.app-wrapper--desktop #app {
    border-left: 1px solid var(--border-color);
    border-right: 1px solid var(--border-color);
}
.app-wrapper--desktop #app::before,
.app-wrapper--desktop #app::after {
    content: "";
    position: fixed;
    top: 0;
    z-index: 500;
    display: block;
    width: 100vw;
    height: 100vh;
    background-color: #eee;
}
.app-wrapper--desktop #app::before {
    right: calc(50% + 25vh);
}
.app-wrapper--desktop #app::after {
    left: calc(50% + 25vh);
}
#app > .v-dialog__content,
#app > .v-overlay {
    width: 50vh;
    max-width: 100%;
    left: 50%;
    bottom: 0;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}
.app-wrapper--mobile #app > .v-dialog__content,
.app-wrapper--mobile #app > .v-overlay {
    width: 100%;
}
.app-wrapper--desktop ::v-deep.v-dialog__content{
    width: 50vh;
    left: 50%;
    -webkit-transform: translate(-50%,0); -ms-transform: translate(-50%,0); transform: translate(-50%,0);
}
</style>
