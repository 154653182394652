<template>
    <v-app-bar v-if="header.shows" fixed height="56">
        <v-btn v-if="isLeftNav(LEFT_NAV_MODES.MENU)" icon tile class="v-btn--none-active-bg" @click="setShowsDrawer(true)">
            <v-img max-width="34" src="/images/symbol.svg" />
        </v-btn>
        <v-btn v-if="isLeftNav(LEFT_NAV_MODES.HOME)" icon tile class="v-btn--none-active-bg" @click="goHome">
            <v-icon size="26" color="grey darken-4">mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn v-if="isLeftNav(LEFT_NAV_MODES.BACK)" icon tile class="v-btn--none-active-bg" @click="$router.go(-1)">
            <v-icon size="26" color="grey darken-4">mdi-chevron-left</v-icon>
        </v-btn>
        <v-spacer />
        <v-progress-circular v-if="title == 'loading'" indeterminate size="24" width="2" />
        <!-- <h1 v-else-if="src_logo" class="ma-0 hidden">
            <v-img contain max-width="180" max-height="22" :src="src_logo" :alt="title" />
        </h1> -->
        <h2 class="v-app-bar__tit d-flex flex-column mt-6">
            <span>{{ title }}</span>
            <span class="subtitle-2">{{ userText }}</span>
        </h2>
        <v-spacer />
        <!-- 로그아웃 -->
        <v-btn icon tile class="v-btn--none-active-bg" @click="logout">
            <div class="text-center">
                <v-icon size="26" color="grey">$lock_open_outline</v-icon>
                <div class="font-size-10 grey--text text--darken-4 mt-2">로그아웃</div>
            </div>
        </v-btn>
        <!-- // 로그아웃 -->
        <!-- <v-btn icon tile to="/login" class="v-btn--none-active-bg">
            <div class="text-center">
                <v-icon size="26" color="grey">$lock_outline</v-icon>
                <div class="font-size-10 grey--text text--darken-4 mt-2">로그인</div>
            </div>
        </v-btn> -->
    </v-app-bar>
</template>

<script>
import api from "@/api";
import { mapActions, mapMutations, mapState } from "vuex";
import { LEFT_NAV_MODES, RIGHT_NAV_MODES } from "@/store/ui-header";

export default {
    components: {},
    data: () => ({
        LEFT_NAV_MODES,
        RIGHT_NAV_MODES,
    }),
    computed: {
        ...mapState(["setting"]),
        ...mapState("ui", ["header"]),
        title() {
            return this.header.title || "신진유통";
        },
        logos() {
            return this.setting?.logos || [];
        },
        src_logo() {
            if (this.title.includes("신진유통")) return "/images/logo-sm@2x.png";
            else return;
        },
        userText() {
            let { name, group } = this.$store.state.user || {};
            let userText = "";
            if (group) {
                if (group?.name?.includes?.(" ")) {
                    userText += `${group?.name?.split?.(" ")?.[0]} / `;
                } else {
                    userText += `${group?.name} / `;
                }
            }
            if (name) userText += name;
            userText = userText.trim();
            if (userText[userText.length - 1] == "/") userText = userText.slice(0, userText.length - 2);
            return userText;
        },
    },
    mounted() {
        this.init();
        // window.scrollTo(0, 0); // 라우터 이동 시 스크롤 상단부터 시작
    },
    methods: {
        ...mapActions(["logout"]),
        ...mapMutations(["setSetting"]),
        ...mapMutations("ui", ["setShowsDrawer"]),
        isLeftNav(mode) {
            return this.header.leftNavMode == mode;
        },
        isRightNav(mode) {
            return this.header.rightNavMode == mode;
        },
        async init() {
            if (!this.setting) {
                const { setting } = await api.v1.setting.get();
                this.setSetting({ setting });
            }
        },
        goHome() {
            let path = "/home";
            if (this.$route.path != path) this.$router.push({ path });
        },
    },
};
</script>

<style scoped>
.app-wrapper--desktop .v-app-bar.v-app-bar--fixed {
    width: calc(50vh - 2px);
    left: 50% !important;
    -webkit-transform: translate(-50%, 0) !important;
    -ms-transform: translate(-50%, 0) !important;
    transform: translate(-50%, 0) !important;
}
.app-wrapper--mobile .v-app-bar.v-app-bar--fixed {
    width: 100%;
}

/* v-app-bar */
>>> .v-toolbar__content {
    padding: 0;
}
.v-app-bar__tit {
    font-size: 1.6rem;
    font-weight: 700;
    color: var(--v-grey-darken4);
    text-align: center;
    word-break: keep-all;
    line-height: 1.3;
    max-height: 2.6em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.theme--light.v-app-bar.v-toolbar.v-sheet,
.v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
    background-color: #fff;
    box-shadow: none;
}
.v-toolbar__content .v-btn.v-btn--icon.v-size--default {
    width: 56px;
    height: 56px !important;
    border-radius: 0;
}
.v-application--is-ltr .v-toolbar__content > .v-btn.v-btn--icon:first-child,
.v-application--is-ltr .v-toolbar__extension > .v-btn.v-btn--icon:first-child {
    margin-left: 0;
}
.v-application--is-ltr .v-toolbar__content > .v-btn.v-btn--icon:last-child,
.v-application--is-ltr .v-toolbar__extension > .v-btn.v-btn--icon:last-child {
    margin-right: 0;
}
</style>
