var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: _vm.isMobile ? 'app-wrapper--mobile' : 'app-wrapper--desktop',
    attrs: {
      "id": "app-wrapper"
    }
  }, [_c('v-app', {
    style: _vm.style,
    attrs: {
      "id": "app"
    }
  }, [_c('app-header'), _c('router-view', {
    key: _vm.$i18n.locale
  }), _c('app-footer')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }