import Vue from 'vue'
import store from '../store'

Vue.prototype.$getAmount = function(pickup){
    var scope = (store.state.payload || {}).scope || [];
    var amount = (scope.includes("company") ? pickup.companySalePrice + pickup.companyDiscountPrice : pickup.salePrice + pickup.discountPrice) * pickup.quantity;
    return parseInt(+amount || 0);
},
Vue.prototype.$getSaleAmount = function(pickup){
    var scope = (store.state.payload || {}).scope || [];
    var saleAmount = (scope.includes("company") ? pickup.companySalePrice : pickup.salePrice) * pickup.quantity;
    return parseInt(+saleAmount || 0);
},
Vue.prototype.$getDiscountAmount = function(pickup){
    var scope = (store.state.payload || {}).scope || [];
    var amount = (scope.includes("company") ? pickup.companyDiscountPrice : pickup.discountPrice) * pickup.quantity;
    return parseInt(+amount || 0);
},
Vue.prototype.$getCouponAmount = function(coupon, amount){
    switch(coupon.unit){
        case "%": {
            let discountAmount = amount * coupon.amount / 100;
            return coupon.maxAmount < discountAmount ? coupon.maxAmount : discountAmount;
        }
        case "원": {
            return amount < coupon.amount ? amount : coupon.amount;
        }

    }
}