import './plugins/prototype';

import Vue from 'vue'
import axios from 'axios'
import router from './router'
import store from './store'
import vuetify, { basicTheme } from './plugins/vuetify'
import { i18n } from './plugins/vue-i18n';
import './plugins/vue-meta';
import './plugins/vue-dayjs';
import './plugins/vue-cookies';
import './plugins/vue-prototypes-store';
import './plugins/vue-youtube';
import './plugins/vue-handle-error'

import App from './AppMain.vue'

Vue.prototype.$http = axios;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  vuetify: vuetify(basicTheme),
  render: h => h(App)
}).$mount('#app')